import { InvoiceStatus } from '../types';

export interface InvoiceReceipt {
  id: number;
  progressive_sending: string;
  transmission_format: string;
  document_type: 'TD01' | 'TD02' | 'TD03' | 'TD04' | 'TD05' | 'TD06' | 'TD24';
  document_date: string;
  document_number: number;
  sender_vat_code: number;
  sender_name: string;
  receiver_vat_code: number;
  total_taxable: number;
  expenses: ReceiptExpenses[];
  total_taxes: number;
  sender_nic: string;
  received_date: string;
  attachments: boolean;
  rounding: number;
  paid: number;
  total: number;
  total_document?: number;
  status: InvoiceStatus;
  payments: any[];
}

// Generated by https://quicktype.io

export interface ReceiptExpenses {
  id: number;
  reservation_id: number;
  property_id: number;
  customer_id: number;
  currency_id: number;
  registration_date: string;
  amount: string;
  notes: string;
  roomreservation_id: any;
  title: string;
  vat_quote_id: number;
  vat_quote_value: string;
  expense_category_id: number;
}

export const INVOICE_RECEIPT_TYPE = {
  TD01: 'invoice',
  TD02: 'down_payment_or_advance_on_invoice',
  TD03: 'down_payment_or_advance_on_fee',
  TD04: 'credit_note',
  TD05: 'debt_note',
  TD06: 'fee',
};

/*
  TD01 => fattura
  TD02 => acconto/anticipo su fattura
  TD03 => acconto/anticipo su parcella
  TD04 => nota di credito
  TD05 => nota di debito
  TD06 => parcella
*/
